import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';

import { ExcludeUnexpected, notUndefined } from '@mablemarket/common-lib';
import { Category, DietCategory, SellerPatchAllowedCategory } from '@mablemarket/core-api-client';

export interface CategoryTreeNode extends Category {
  childCategories?: CategoryTreeNode[];
}

/**
 * @param categories: Requires relevant categories to have parentMemberships
 */
export const buildCategoryTree = (categories: Category[]): CategoryTreeNode[] => {
  const cats: CategoryTreeNode[] = categories.map(c => ({ ...c, childCategories: undefined }));
  const roots: CategoryTreeNode[] = [];
  const keyedCats = keyBy(cats, c => c.id);
  cats.forEach((c) => {
    const parents = c
      .parentMemberships?.map(({ parentCategoryId }): CategoryTreeNode | undefined => keyedCats[parentCategoryId])
      .filter(notUndefined) ?? [];
    if (!isEmpty(parents)) {
      parents.forEach((parent) => {
        parent.childCategories = parent.childCategories ?? [];
        parent.childCategories.push(c);
      });
    } else {
      roots.push(c);
    }
  });
  return roots;
};

export interface KnownCategory {
  name: string;
  slug: string;
  patternsForSuggestion: (RegExp | string)[];
}

/* eslint-disable quote-props */
export const knownCategoryDetails = {
  'bars': {
    name: 'Bars',
    patternsForSuggestion: [
    ],
  },
  'beverages': {
    name: 'Beverages',
    patternsForSuggestion: [
    ],
  },
  'condiments-and-sauces': {
    name: 'Condiments & Sauces',
    patternsForSuggestion: [
    ],
  },
  'frozen-foods': {
    name: 'Frozen Foods',
    patternsForSuggestion: [
      'Frozen Foods',
      'frozen-foods',
    ],
  },
  'beauty-personal-care': {
    name: 'Beauty & Personal Care',
    patternsForSuggestion: [
    ],
  },
  'oils-and-dressings': {
    name: 'Oils & Dressings',
    patternsForSuggestion: [
    ],
  },
  'pet': {
    name: 'Pet',
    patternsForSuggestion: [
      'pet',
    ],
  },
  'soups': {
    name: 'Soups',
    patternsForSuggestion: [
    ],
  },
  'candy-confections': {
    name: 'Candy & Confections',
    patternsForSuggestion: [
    ],
  },
  'nuts-seeds-dried-fruit': {
    name: 'Nuts, Seeds & Dried Fruit',
    patternsForSuggestion: [
    ],
  },
  'home': {
    name: 'Home',
    patternsForSuggestion: [
    ],
  },
  'jerky': {
    name: 'Jerky',
    patternsForSuggestion: [
      'jerky',
    ],
  },
  'bread-and-baked-goods': {
    name: 'Bread & Baked Goods',
    patternsForSuggestion: [
    ],
  },
  'nut-butter-and-jam': {
    name: 'Nut Butter & Jam',
    patternsForSuggestion: [
    ],
  },
  'babies-and-kids': {
    name: 'Babies & Kids',
    patternsForSuggestion: [
    ],
  },
  'maple-syrup': {
    name: 'Maple Syrup',
    patternsForSuggestion: [
      'Maple Syrup',
    ],
  },
  'other': {
    name: 'Other',
    patternsForSuggestion: [
    ],
  },
  'chocolate-and-desserts': {
    name: 'Chocolate & Desserts',
    patternsForSuggestion: [
      'Chocolate',
    ],
  },
  'coffee-and-tea': {
    name: 'Coffee & Tea',
    patternsForSuggestion: [
      'coffee',
      'tea',
    ],
  },
  'grains-pasta-rice': {
    name: 'Grains, Pasta & Rice',
    patternsForSuggestion: [
    ],
  },
  'snacks': {
    name: 'Snacks',
    patternsForSuggestion: [
    ],
  },
  'granola-and-cereal': {
    name: 'Granola & Cereal',
    patternsForSuggestion: [
    ],
  },
  'honey': {
    name: 'Honey',
    patternsForSuggestion: [
    ],
  },
  'pantry': {
    name: 'Pantry',
    patternsForSuggestion: [
    ],
  },
  'bakery': {
    name: 'Bakery',
    patternsForSuggestion: [
    ],
  },
  'chocolate-and-sweets': {
    name: 'Chocolate & Sweets',
    patternsForSuggestion: [
    ],
  },
  'drinks': {
    name: 'Drinks',
    patternsForSuggestion: [
    ],
  },
  'health-lifestyle': {
    name: 'Health & Lifestyle',
    patternsForSuggestion: [
    ],
  },
  'jam-honey-button-syrup': {
    name: 'Jam, Honey, Butter & Syrup',
    patternsForSuggestion: [
    ],
  },
  'salts-spices': {
    name: 'Salts & Spices',
    patternsForSuggestion: [
    ],
  },
  'chips-and-crackers': {
    name: 'Chips & Crackers',
    patternsForSuggestion: [
    ],
  },
  'vegetables': {
    name: 'Vegetables',
    patternsForSuggestion: [
    ],
  },
  'eco-friendly': {
    name: 'Eco friendly',
    patternsForSuggestion: [
      'Eco friendly',
    ],
  },
  'dairy-free': {
    name: 'Dairy free',
    patternsForSuggestion: [
      'Dairy free',
      'no dairy',
    ],
  },
  'woman-owned': {
    name: 'Woman owned',
    patternsForSuggestion: [
      'Woman owned',
      'women owned',
    ],
  },
  'vegetarian': {
    name: 'Vegetarian',
    patternsForSuggestion: [
      'Vegetarian',
    ],
  },
  'small-batch': {
    name: 'Small batch',
    patternsForSuggestion: [
      'Small batch',
    ],
  },
  'raw': {
    name: 'Raw',
    patternsForSuggestion: [
      'Raw',
      'raw',
    ],
  },
  'vegan': {
    name: 'Vegan',
    patternsForSuggestion: [
      'vegan',
    ],
  },
  'hand-made': {
    name: 'Handmade',
    patternsForSuggestion: [
      'Handmade',
      'hand-made',
      'made by hand',
    ],
  },
  'upcycled': {
    name: 'Upcycled',
    patternsForSuggestion: [
      'upcycled',
    ],
  },
  'paleo': {
    name: 'Paleo',
    patternsForSuggestion: [
      'paleo',
    ],
  },
  'non-gmo': {
    name: 'Non-GMO',
    patternsForSuggestion: [
      'non-gmo',
      'gmo free',
      'no gmo',
    ],
  },
  'keto': {
    name: 'Keto',
    patternsForSuggestion: [
      'keto',
    ],
  },
  'kosher': {
    name: 'Kosher',
    patternsForSuggestion: [
      'kosher',
    ],
  },
  'made-in-usa': {
    name: 'Made in USA',
    patternsForSuggestion: [
      'Made in USA',
    ],
  },
  'baking': {
    name: 'Baking',
    patternsForSuggestion: [
      'baking',
    ],
  },
  'dairy-and-cheese': {
    name: 'Dairy & Cheese',
    patternsForSuggestion: [
    ],
  },
  'meat-and-seafood': {
    name: 'Meat & Seafood',
    patternsForSuggestion: [
    ],
  },
  'vitamins-and-supplements': {
    name: 'Vitamins & Supplements',
    patternsForSuggestion: [
    ],
  },
  'gluten-free': {
    name: 'Gluten Free',
    patternsForSuggestion: [
      'Gluten Free',
    ],
  },
  'cbd': {
    name: 'CBD',
    patternsForSuggestion: [
      'cbd',
    ],
  },
  'bipoc-owned': {
    name: 'BIPOC Owned',
    patternsForSuggestion: [
      'Minority Owned',
      'BIPOC Owned',
    ],
  },
  'aapi-owned': {
    name: 'AAPI Owned',
    patternsForSuggestion: [
      'AAPI Owned',
      'Asian Owned',
    ],
  },
  'lgbtqia-owned': {
    name: 'LGBTQIA+ Owned',
    patternsForSuggestion: [
      'LGBTQIA+ Owned',
      'LGBT Owned',
    ],
  },
  'not-on-amazon': {
    name: 'Not on Amazon',
    patternsForSuggestion: [
      'Not on Amazon',
    ],
  },
  'veteran-owned': {
    name: 'Veteran Owned',
    patternsForSuggestion: [
      'Veteran Owned',
      'Vet Owned',
    ],
  },
  'cleansers-and-sanitizers': {
    name: 'Cleansers & Sanitizers',
    patternsForSuggestion: [
    ],
  },
  'bulk': {
    name: 'Bulk',
    patternsForSuggestion: [
      'Bulk',
      'bulk',
    ],
  },
  'certified-organic': {
    name: 'Certified Organic',
    patternsForSuggestion: [
      'certified-organic',
    ],
  },
  'organic': {
    name: 'Organic',
    patternsForSuggestion: [
      'organic',
    ],
  },
  'sale': {
    name: 'Sale',
    patternsForSuggestion: [
      'sale',
    ],
  },
  'distributor-brand': {
    name: 'distributor-brand',
    patternsForSuggestion: [
    ],
  },
  'distributor-new': {
    name: 'distributor-new',
    patternsForSuggestion: [
    ],
  },
  'grain-free': {
    name: 'Grain Free',
    patternsForSuggestion: [
      'Grain Free',
    ],
  },
  'wine-spirits': {
    name: 'Wine & Spirits',
    patternsForSuggestion: [
    ],
  },
  'black-owned': {
    name: 'Black Owned',
    patternsForSuggestion: [
      'Black Owned',
      'black-owned',
    ],
  },
  'refrigerated': {
    name: 'Refrigerated',
    patternsForSuggestion: [],
  },
  'plant-based': {
    name: 'Plant Based',
    patternsForSuggestion: [
      'plant based',
      'plant-based',
    ],
  },
  'whole-grain': {
    name: 'Whole Grain',
    patternsForSuggestion: [
      'whole grain',
    ],
  },
  'bio-based': {
    name: 'Bio-Based',
    patternsForSuggestion: [
      'bio-based',
      'biobased',
    ],
  },
  'bio-dynamic': {
    name: 'Bio-Dynamic',
    patternsForSuggestion: [],
  },
  'cruelty-free': {
    name: 'Cruelty Free',
    patternsForSuggestion: [],
  },
  'diabetic-friendly': {
    name: 'Diabetic Friendly',
    patternsForSuggestion: [],
  },
  'fair-trade': {
    name: 'Fair Trade',
    patternsForSuggestion: [],
  },
  'grass-fed': {
    name: 'Grass Fed',
    patternsForSuggestion: [],
  },
  'halal': {
    name: 'Halal',
    patternsForSuggestion: [],
  },
  'heart-healthy': {
    name: 'Heart Healthy',
    patternsForSuggestion: [],
  },
  'biodegradable': {
    name: 'Biodegradable',
    patternsForSuggestion: [],
  },
} as const;

export type KnownCategorySlug = keyof typeof knownCategoryDetails;

type DietCategoryWithName = {
  name: string;
  slug: ExcludeUnexpected<DietCategory>,
}
export const dietCategories: readonly DietCategoryWithName[] = [{
  name: 'Certified Organic',
  slug: 'certified-organic',
}, {
  name: 'Dairy Free',
  slug: 'dairy-free',
}, {
  name: 'Gluten Free',
  slug: 'gluten-free',
}, {
  name: 'Keto',
  slug: 'keto',
}, {
  name: 'Kosher',
  slug: 'kosher',
}, {
  name: 'Non-GMO',
  slug: 'non-gmo',
}, {
  name: 'Paleo',
  slug: 'paleo',
}, {
  name: 'Vegan',
  slug: 'vegan',
}, {
  name: 'Vegetarian',
  slug: 'vegetarian',
}, {
  name: 'Whole 30 Approved',
  slug: 'whole-30-approved',
}, {
  name: 'Raw',
  slug: 'raw',
},
] as const;

export const productValueCategories = [{
  name: 'Fair Trade',
  slug: 'fair-trade',
}, {
  name: 'Handmade',
  slug: 'hand-made',
}, {
  name: 'Small Batch',
  slug: 'small-batch',
}, {
  name: 'Upcycled',
  slug: 'upcycled',
}, {
  name: 'Made in USA',
  slug: 'made-in-usa',
},
] as const;

export const productDietAndValueCategories = [
  ...dietCategories,
  ...productValueCategories,
];

export const slugToAllowedPatchMapper = ({ slug }: { slug: string }) => {
  if (SellerPatchAllowedCategory.is(slug)) {
    return slug;
  }
  return undefined;
};
