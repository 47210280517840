import * as t from 'io-ts';

import { ExcludeUnexpected, NonEmptyStringOrUndefined, Nullable, PhoneNumber, Zipcode } from '@mablemarket/common-lib';
import { DataStandardPropertyAssociableEntity, DataStandardPropertyName, DataStandardPropertyValueType, ManualTaskType, ProductIssueIssueInfo } from '@mablemarket/core-api-client';

export const BulkAccountImportCSV = t.intersection([
  t.partial({
    ACCOUNT_NAME: NonEmptyStringOrUndefined,
    EMAIL: NonEmptyStringOrUndefined,
    TAGS: NonEmptyStringOrUndefined,
    HUBSPOT_COMPANY_ID: NonEmptyStringOrUndefined,
  }),
  t.type({
    STORE_IDENTIFIER: t.string,
    STORE_NAME: t.string,
    ADDRESS1: t.string,
    ADDRESS2: NonEmptyStringOrUndefined,
    CITY: t.string,
    STATE: t.string,
    ZIP: Zipcode,
    PHONE: PhoneNumber,
    ORGANIZATION: t.string,
    FIRST_NAME: NonEmptyStringOrUndefined,
    LAST_NAME: NonEmptyStringOrUndefined,
  }),
]);
export type BulkAccountImportCSV = t.TypeOf<typeof BulkAccountImportCSV>;

export const SendbirdPushNotificationPayload = t.partial({
  app_id: t.string,
  audience_type: t.string,
  category: t.string,
  channel: t.partial({
    channel_unread_message_count: t.number,
    channel_url: t.string,
    custom_type: t.string,
    name: t.string,
  }),
  channel_type: t.string,
  created_at: t.number,
  custom_type: t.string,
  files: t.UnknownArray,
  mentioned_users: t.array(t.partial({
    user_id: t.string,
    nickname: t.string,
    profile_url: t.string,
    require_auth_for_profile_image: t.boolean,
  })),
  message: t.string,
  message_id: t.number,
  notification_action: t.string,
  push_sound: t.string,
  push_title: Nullable(t.string),
  recipient: t.partial({
    id: t.string,
    name: t.string,
    push_template: t.string,
  }),
  sender: t.partial({
    id: t.string,
    name: t.string,
    profile_url: t.string,
    require_auth_for_profile_image: t.boolean,
    metadata: t.UnknownRecord,
  }),
  session_key: t.UnknownRecord,
  sqs_ts: t.number,
  translations: t.UnknownRecord,
  type: t.string,
  unread_message_count: t.number,
});
export type SendbirdPushNotificationPayload = t.TypeOf<typeof SendbirdPushNotificationPayload>;

/** Data communicated through a column name in a CSV format meant for bulk data standard property updating3 */
export const DataStandardPropertyCsvColumnName = t.type({
  propertyName: DataStandardPropertyName,
  valueType: DataStandardPropertyValueType,
  associatedEntityType: DataStandardPropertyAssociableEntity,
});

/** Converts the data standard property data to and from a CSV column name. */
export const DataStandardPropertyCsvColumnNameCodec = new t.Type(
  'DataStandardPropertyCsvColumnNameCodec',
  DataStandardPropertyCsvColumnName.is,
  (u, c) => {
    if (typeof u !== 'string') {
      return t.failure(u, c);
    }
    const matches = /dsp__(.*?)__(.*?)__(.*)/.exec(u);
    if (!matches) {
      return t.failure(u, c, 'Unexpected string format');
    }

    const [, propertyName, valueType, associatedEntityType] = matches;
    const obj = {
      propertyName,
      valueType,
      associatedEntityType,
    };
    return DataStandardPropertyCsvColumnName.validate(obj, c);
  },
  a => `dsp__${a.propertyName}__${a.valueType}__${a.associatedEntityType}`,
);


export const ExternalLocationPurchaseOrderErrorData = t.type({
  partnerStoreIdentifier: t.string,
  purchaseOrderNumber: t.string,
  ediFileId: t.number,
  operationIds: t.array(t.number),
});
export type ExternalLocationPurchaseOrderErrorData = t.TypeOf<typeof ExternalLocationPurchaseOrderErrorData>;

export const manualTaskTypeToDataCodec = {
  buyerCoversShippingCheck: t.undefined,
  buyerInMultiplePricingTiersPerSeller: t.undefined,
  buyerRequestedOrderGuideUpdate: t.undefined,
  buyerRequestedSample: t.undefined,
  deletedUser: t.undefined,
  ediCreditRequested: t.undefined,
  ediFileProcessingError: t.type({
    ediFileId: t.number,
  }),
  ediNegativeFunctionalAckReceived: t.undefined,
  futureLandedCostsUpdated: t.undefined,
  highPurchaseTotalPartnerships: t.undefined,
  inconsistentlyDeletedVariants: t.undefined,
  missingFreeShippingMin: t.undefined,
  missingNACSCategoryInPartnerAPL: t.undefined,
  noNewOrders: t.undefined,
  nonReducingPriceReplacements: t.undefined,
  noExternalLocationInformationForPurchaseOrder: ExternalLocationPurchaseOrderErrorData,
  oldAuthorizedPayments: t.undefined,
  oldPausedFulfillmentRoutingOrders: t.undefined,
  orphanedProductVariantIdentifiers: t.undefined,
  oneWorldSyncProblem: t.undefined,
  parsingErrorForX12FunctionalAck: t.undefined,
  partnerAccountConfiguration: t.undefined,
  partnerAPLIncludesBuyerAPLVariants: t.undefined,
  partneredSellersMissingPartneredProducts: t.undefined,
  partnerPriceReview: t.undefined,
  partnerPricingMismatch: t.undefined,
  personalizedPricingCheck: t.undefined,
  processExternalLocationInformationForPurchaseOrder: ExternalLocationPurchaseOrderErrorData,
  productAuthorizationRequestFailure: t.undefined,
  retailUnitNeedsUsFoodsImageClassification: t.type({
    retailUnitId: t.number,
    sellerId: t.number,
  }),
  sellerMissingNavigableCategory: t.undefined,
  sellerShippingCharges: t.undefined,
  stuckEdiFileUploads: t.undefined,
  transactionAdjustmentRequest: t.undefined,
  unbalancedCompleteOrders: t.undefined,
  unbalancedSplitGroups: t.undefined,
  undeliverableEmail: t.undefined,
  unsentBrandPOs: t.undefined,
  unsentDistributorPOs: t.undefined,
  unsentPartnerInvoices: t.undefined,
  unshippedPriorityOrder: t.undefined,
  whitelistCheckForDistributorBuyerRelationships: t.undefined,
} satisfies Record<ExcludeUnexpected<ManualTaskType>, unknown>;

export const RetailUnitDataIssuesErrorData = t.type({
  retailUnitId: t.number,
  issues: t.array(ProductIssueIssueInfo),
});
export type RetailUnitDataIssuesErrorData = t.TypeOf<typeof RetailUnitDataIssuesErrorData>;
