import BigNumber from 'bignumber.js';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import camelCase from 'lodash/camelCase';
import deburr from 'lodash/deburr';
import first from 'lodash/first';
import flatMap from 'lodash/flatMap';
import fromPairs from 'lodash/fromPairs';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import upperFirst from 'lodash/upperFirst';
import { toWords } from 'number-to-words';
import pluralize from 'pluralize';
import * as yup from 'yup';

import { assertExhaustive, ExcludeUnexpected, formatShelfLifeDays, getEnumValue, getValueOrThrow, Gtin14, isMableError, isNotUnexpected, isNotUnexpectedByKey, isTruthy, isUnexpected, notUndefined, parseBarcode, Slug, SnakeToCamelCase } from '@mablemarket/common-lib';
import { Allergen, BaseProductVariant, Image, Product, ProductAvailability, ProductStorage, ProductStorageType, ProductStorageTypeValues, ProductVariant, ProductVariantDisplayInfo, ProductVariantPackaging, ProductVariantPackagingDistanceUnit, ProductVariantPackagingLevel, ProductVariantPackagingLevelValues, ProductVariantPackagingWeightUnit, SellerDisplayInfo } from '@mablemarket/core-api-client';


import * as MableURI from '../MableURI';

import * as ImageHelpers from './ImageHelpers';

export function getAvailability(info: {
  variant: Pick<ProductVariant, 'availability' | 'backInStockDate'>;
  seller?: Pick<SellerDisplayInfo, 'comingSoon'>;
}): ProductAvailability {
  const {
    variant,
    seller,
  } = info;

  if (seller && seller.comingSoon) {
    return 'comingSoon';
  }

  if (isBackordered(variant)) {
    return 'backordered';
  }
  if (variant.availability === 'backordered') {
    return 'inStock';
  }

  return variant.availability ?? 'inStock';
}

export function isBackordered(
  variant: Pick<BaseProductVariant, 'availability' | 'backInStockDate'>,
  opts: { fromDate?: Date } = {},
) {
  const { fromDate = new Date() } = opts;
  const { availability, backInStockDate } = variant;
  return availability === 'backordered'
    && backInStockDate
    && differenceInCalendarDays(backInStockDate, fromDate) > 0;
}

export function formatBackInStockDate(
  backInStockDate?: Date,
  opts: { fromDate?: Date, locale?: string | string[], format?: Intl.DateTimeFormatOptions } = {},
) {
  const {
    fromDate = new Date(),
    locale = 'en-us',
    format = { month: 'long', day: 'numeric', year: 'numeric', timeZone: 'UTC' },
  } = opts;
  return (backInStockDate && differenceInCalendarDays(backInStockDate, fromDate) > 0)
    ? backInStockDate.toLocaleDateString(locale, format)
    : undefined;
}

export function isOnSale(variant?: Pick<BaseProductVariant, 'price' | 'offSalePrice'>) {
  if (!variant) {
    return false;
  }
  if (variant.price === undefined || isEmpty(variant.price)) {
    return false;
  }
  if (variant.offSalePrice === undefined) {
    return false;
  }
  return new BigNumber(variant.price).lt(variant.offSalePrice);
}

export function salePercentOff(variant?: Pick<BaseProductVariant, 'price' | 'offSalePrice'>) {
  const { price, offSalePrice } = variant ?? {};
  if (!variant || price === undefined || isEmpty(price) || offSalePrice === undefined || Number(price) <= 0) {
    return '0';
  }
  return (new BigNumber(price)).div(offSalePrice)
    .minus(1)
    .times(-100)
    .toFixed(0, 0);
}

export function isCase(variant: Pick<ProductVariant, 'eachCount'>) {
  return Boolean(variant.eachCount && variant.eachCount > 1);
}

export function perUnitDisplay(
  variant: Partial<Pick<ProductVariant, 'caseName' | 'eachName' | 'eachCount'>>,
  opts: { capitalize?: boolean } = { capitalize: true },
): string {
  let caseName = variant.caseName || 'case';
  let eachName = variant.eachName || 'each';

  if (opts?.capitalize) {
    caseName = caseName.replace(/^\w/, c => c.toUpperCase());
    eachName = eachName.replace(/^\w/, c => c.toUpperCase());
  } else {
    caseName = caseName.toLowerCase();
    eachName = eachName.toLowerCase();
  }

  return isCase(variant) ? caseName : eachName;
}

/**
 * Display text for "Case of 12 Items" using caseName, if it exists. e.g. "Box of 6 Bottles".
 * returns undefined if the variant is not a case.
 */
export function caseOfEachCount(
  variant: Partial<Pick<ProductVariant, 'eachName' | 'eachCount' | 'eachNamePlural'>>,
  opts: {
    capitalize?: boolean;
    includeEachName?: boolean;
  } = { capitalize: true, includeEachName: true },
) {
  if (!isCase(variant)) {
    return undefined;
  }

  let eachNameDisplay;
  if (opts.includeEachName && variant.eachName) {
    if (variant.eachCount && variant.eachCount > 1) {
      eachNameDisplay = variant.eachNamePlural || variant.eachName;
    } else { // Singular?
      eachNameDisplay = variant.eachName;
    }
  }

  if (opts.capitalize) {
    eachNameDisplay = eachNameDisplay?.replace(/^\w/, c => c.toUpperCase());
  } else {
    eachNameDisplay = eachNameDisplay?.toLowerCase();
  }

  if (variant.eachName === 'lb') {
    return `${variant.eachCount} lbs`;
  }

  return `${perUnitDisplay(variant, { capitalize: Boolean(opts.capitalize) })} of ${variant.eachCount}${eachNameDisplay ? ` ${eachNameDisplay}` : ''}`;
}

/**
 * @returns Singular 'size eachName' e.g. '4 oz jar'. Will fall back to '4 oz item' or 'jar' if either size or eachName is missing.
 */
export const unitNameWithSize = (opts: {
  variant: Pick<ProductVariant, 'eachSize' | 'eachSizeUnit' | 'eachName'>;
  capitalize?: boolean;
}) => {
  const {
    variant,
    capitalize = true,
  } = opts;

  const thisEachSizeWithUnit = eachSizeWithUnit(variant);

  // Fallback to 'Item' only if there is a size
  let eachItemName = variant.eachName ?? (thisEachSizeWithUnit ? 'Item' : undefined);
  if (eachItemName) {
    if (capitalize) {
      eachItemName = eachItemName?.replace(/^\w/, c => c.toUpperCase());
    } else {
      eachItemName = eachItemName.toLowerCase();
    }
  }

  if (!thisEachSizeWithUnit && !eachItemName) {
    return undefined;
  }

  return [
    thisEachSizeWithUnit,
    eachItemName,
  ].filter(s => s).join(' ');
};

/**
 * @returns e.g. "4 oz" or "100 pieces" or "4 oz • 100 pieces"
 */
export const eachSizeWithUnit = (variant: Pick<ProductVariant, 'eachSize' | 'eachSizeUnit' | 'eachPieceCount' | 'eachPieceName' | 'eachPieceNamePlural'>) => {
  const eachDisplay = (variant.eachSize && variant.eachSize.isFinite() && variant.eachSizeUnit)
    // eslint-disable-next-line no-underscore-dangle
    ? `${variant.eachSize} ${isNotUnexpected(variant.eachSizeUnit) ? variant.eachSizeUnit : getEnumValue(variant.eachSizeUnit)}`
    : undefined;

  const singlePieceDisplay = variant.eachPieceCount === 1
    ? `${variant.eachPieceCount} ${variant.eachPieceName ?? 'piece'}`
    : undefined;

  const multiPieceDisplay = (variant.eachPieceCount ?? 0) > 1
    ? `${variant.eachPieceCount} ${variant.eachPieceNamePlural ?? 'pieces'}`
    : undefined;

  const display = [eachDisplay, singlePieceDisplay ?? multiPieceDisplay].filter(isTruthy);
  return isEmpty(display) ? undefined : display.join(' • ');
};

/**
 * @returns '4 jars', '4 items' or `undefined` if no `eachCount` is defined.
 */
export const eachCountWithEachName = (opts: {
  variant: Pick<ProductVariant, 'eachCount' | 'eachName' | 'eachNamePlural'>;
  capitalize?: boolean;
}) => {
  const {
    variant,
    capitalize = true,
  } = opts;

  if (!variant.eachCount) {
    return undefined;
  }

  let eachNameDisplay;
  if (variant.eachCount > 1) {
    eachNameDisplay = variant.eachNamePlural || variant.eachName || 'Items';
  } else { // Singular
    eachNameDisplay = variant.eachName || 'Item';
  }

  if (capitalize) {
    eachNameDisplay = eachNameDisplay?.replace(/^\w/, c => c.toUpperCase());
  } else {
    eachNameDisplay = eachNameDisplay.toLowerCase();
  }

  return `${variant.eachCount} ${eachNameDisplay}`;
};

export const eachSoldAs = (variant: Pick<ProductVariant, 'eachSize' | 'eachSizeUnit' | 'eachCount' | 'eachName' | 'eachPieceCount' | 'eachPieceName'>) => {
  const eachSizeWithUnitResult = eachSizeWithUnit({
    eachSize: variant.eachSize,
    eachSizeUnit: variant.eachSizeUnit,
  })?.replace(/\s/g, '-') ?? '';

  const soldAs = [
    (variant.eachCount && variant.eachCount > 1)
      ? `case of ${toWords(variant.eachCount)}` : undefined,
    eachSizeWithUnitResult,
    variant.eachName,
    (!!variant.eachPieceCount && !!variant.eachPieceName)
      ? `with ${variant.eachPieceCount} ${variant.eachPieceName} each` : undefined,
  ].filter(notUndefined).join(' ');

  return soldAs;
};

export const productVariantName = (opts: {
  product: Pick<Product, 'name'>;
  variant: Pick<ProductVariant, 'options'>;
  includeProductName: boolean;
  experimentallyIgnoreSizeOptions?: boolean;
  order?: 'productFirst' | 'variantFirst';
}) => {
  const {
    product,
    variant,
    includeProductName,
    experimentallyIgnoreSizeOptions,
    order = 'productFirst',
  } = opts;

  let name = '';

  if (variant.options) {
    const filteredOptions = experimentallyIgnoreSizeOptions
      ? fromPairs(Object.entries(variant.options)
        .filter(([optionSetName]) => (
          // TODO: add type enum to option sets so we don't try to tell if it is describing a size
          !(/.*(size|case|each|qty|quantity|unit|pack).*/).test(optionSetName.toLowerCase())
        )))
      : variant.options;
    const options = Object.values(filteredOptions)
      .filter(n => !['default title', 'default', ''].includes(n.toLowerCase()))
      .join(', ');
    if (options.length) {
      name += options;
    }
  }
  if (product &&
    product.name.trim().length &&
    !['default title', 'default'].includes(product.name.toLowerCase())) {
    if (name.length && includeProductName) {
      if (order === 'productFirst') {
        name = `${product.name} - ${name}`;
      } else {
        name = `${name} ${product.name}`;
      }
    } else if (!name.length) {
      name = product.name;
    }
  }
  return name;
};

// TODO: Code duplication with displayInfoForVariant on the backend. Once the backend returns only
// ProductVariantDisplayInfo to mable-web, we can stop doing this.
export function toVariantDisplayInfo(product: Product, variant: ProductVariant, seller: SellerDisplayInfo): ProductVariantDisplayInfo {
  const image = variant.images?.[0] ?? product.images?.[0];
  const imageUrl = image && ImageHelpers.imageURLfromImage(image, '');

  return {
    name: productVariantName({ product, variant, includeProductName: true }),
    price: variant.price,
    imageUrl,
    image,
    links: {
      detailPage: MableURI.product({ product, variant }),
      sellerPage: seller ? MableURI.seller({ seller }) : '', // TODO: Remove empty string hack
    },
    availability: getAvailability({ variant, seller }),
    caseOfEachCount: isCase(variant)
      ? caseOfEachCount(variant, { includeEachName: false })
      : undefined,
    eachSize: variant.eachSize,
    eachSizeUnit: variant.eachSizeUnit,
    eachCount: variant.eachCount,
    eachName: variant.eachName,
    eachPieceCount: variant.eachPieceCount,
    eachPieceName: variant.eachPieceName,
    eachPieceNamePlural: variant.eachPieceNamePlural,
    pricePerEach: variant.pricePerEach,
    priceRetail: variant.priceRetail,
    offSalePrice: variant.offSalePrice,
    shelfLife: variant.shelfLife,
    sellerName: seller?.name ?? '',
    sellerSlug: seller?.slug ?? '' as Slug,
    sellerId: seller?.id ?? product.sellerId,
    productId: product.id,
    variantId: variant.id,
    sku: variant.sku,
    gtin: variant.gtin,
    caseGtin: variant.caseGtin,
    backInStockDate: variant.backInStockDate,
    shippingPolicy: seller.shippingPolicy,
    lists: variant.lists,
    tags: variant.tags,
    sellerState: seller.state,
    purchasable: variant.purchasable,
  };
}

export function sluggify(value: string): Slug {
  // TODO: There is probably a smart way to unify this with the regex in Slug.
  // This will fail at runtime if they are mismatched, which shouldn't prevent
  // anything mission-critical but that not a gaurantee.
  return getValueOrThrow(Slug.decode(
    deburr(value)
      .toLowerCase()
      .replace(/&/g, '-and-')
      .replace(/[^a-z0-9\-\s]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-'),
  ));
}

export function gtinify(value?: unknown): Gtin14 {
  if (Gtin14.is(value)) {
    return value;
  }
  const parsed = parseBarcode(`${value}`);
  if (isMableError(parsed)) {
    throw parsed;
  }
  return getValueOrThrow(Gtin14.decode(parsed.GTIN14));
}

export function gtinifyOrUndefined(value: unknown): Gtin14 | undefined {
  try {
    return gtinify(value);
  } catch (e) {
    return undefined;
  }
}

// ////////////////////////////////////////////////////////////////////////////////
// Images

export const firstImageFromProduct = (product?: Product): (Image | undefined) => {
  if (!product) {
    return undefined;
  }
  if (first(product.images)) {
    return first(product.images);
  }
  const variant = first(product.variants);
  if (variant && first(variant.images)) {
    return first(variant.images);
  }
  return undefined;
};

export const imageForVariant = (opts: {
  variant: ProductVariant;
  product?: Product;
}): (Image | undefined) => {
  const {
    variant,
    product,
  } = opts;

  return first(variant.images) || firstImageFromProduct(product);
};

export const imageForVariantId = (opts: {
  variantId: (number | undefined);
  product: Product;
  fallbackToProduct?: boolean;
}): (Image | undefined) => {
  const {
    variantId,
    product,
    fallbackToProduct = false,
  } = opts;

  if (variantId) {
    const variant = product.variants && product.variants.find(variant => variant.id === variantId);
    if (variant) {
      return imageForVariant({
        variant,
        product: fallbackToProduct ? product : undefined,
      });
    }
  }

  return firstImageFromProduct(product);
};

export const allImagesFromProduct = (product: Product): Image[] => {
  const allImages = [];
  if (product.images) {
    allImages.push(...product.images);
  }

  if (product.variants) {
    const variantImageArrs = product.variants.map(v => v.images || []);
    variantImageArrs.forEach((arr) => {
      allImages.push(...arr);
    });
  }

  return allImages;
};

export const fuseKeyObjectsForProduct = (keyPrefix?: string) => {
  const prefix = keyPrefix ?? '';
  const strongKeys = [
    'name',
    'externalId',
  ].map((key) => {
    return {
      name: `${prefix}${key}`,
      weight: 0.7,
    };
  });
  const weakKeys = [
    'description',
  ].map((key) => {
    return {
      name: `${prefix}${key}`,
      weight: 0.3,
    };
  });

  return [
    ...strongKeys,
    ...weakKeys,
  ];
};

export const fuseKeyObjectsForProductVariant = (opts: {
  keyPrefix?: string,
  strongKeyNames?: (keyof ProductVariantDisplayInfo)[],
}) => {
  const {
    keyPrefix,
    strongKeyNames = ['name'],
  } = opts;
  const prefix = keyPrefix ?? '';
  const strongKeys = strongKeyNames.map((key) => {
    return {
      name: `${prefix}${key}`,
      weight: 0.7,
    };
  });
  const weakKeys = [
    'eachSizeUnit',
    'eachName',
    'caseName',
    'description',
    'ingredients',
  ].map((key) => {
    return {
      name: `${prefix}${key}`,
      weight: 0.3,
    };
  });
  // TODO: Something with 'searchKey'

  return [
    ...strongKeys,
    ...weakKeys,
  ];
};

export const allProductCategories = (product: Product) => {
  return uniqBy(product.variants.flatMap(v => v.categories ?? []), c => c.id);
};

export const matchAllergens = (props: { ingredients: string, allergens: Allergen[], lowerCase?: boolean }) => {
  const { ingredients, allergens, lowerCase } = props;
  const singularAllergens: Allergen[] = allergens
    .filter((ka) => {
      return ka.allergen.substr(-1) === 's' &&
        // Consider "Peas" & "Peanuts" so exclude "Pea" for false positive match against "Peanut".
        !allergens.some((ka2) => {
          return ka2.allergen !== ka.allergen &&
            ka2.allergen.includes(ka.allergen.substr(0, ka.allergen.length - 1));
        });
    })
    .map(ka => ({
      id: ka.id,
      allergen: (lowerCase !== undefined && lowerCase)
        ? ka.allergen.substr(0, ka.allergen.length - 1).toLowerCase()
        : ka.allergen.substr(0, ka.allergen.length - 1),
      isSuggestionGenerated: ka.isSuggestionGenerated,
    }));
  const lowerIngredients = ingredients.toLowerCase();

  const knownAllergensInIngredients = allergens.filter((a) => {
    return lowerIngredients.includes(a.allergen.toLowerCase());
  });
  const singularAllergensInIngredients = singularAllergens
    .filter((a) => {
      return lowerIngredients.includes(a.allergen.toLowerCase());
    })
    .map((a) => {
      // find original, pluralized allergen object with correct name
      return allergens.find(allergen => allergen.id === a.id);
    })
    .filter(notUndefined);
  const matches = uniqBy([...knownAllergensInIngredients, ...singularAllergensInIngredients], 'id');
  return matches;
};

export const shelfLifeStringFromStorages = (storages: ProductStorage[]) => {
  if (isEmpty(storages)) {
    return undefined;
  }

  const sortScores: Record<ExcludeUnexpected<ProductStorageType>, number> = {
    ambient: 1,
    refrigerate: 2,
    frozen: 3,
  };
  const storageTypeDisplay: Record<ExcludeUnexpected<ProductStorageType>, string> = {
    ambient: 'Shelf-stable',
    refrigerate: 'Refrigerated',
    frozen: 'Frozen',
  };

  const joined = storages
    .filter(isNotUnexpectedByKey('storageType'))
    .sort((a, b) => {
      return sortScores[a.storageType] - sortScores[b.storageType];
    })
    .map((s) => {
      const timeSpans = [
        formatShelfLifeDays(s.shelfLifeInDays),
        s.shelfLifeGuaranteedInDays && `guaranteed for ${formatShelfLifeDays(s.shelfLifeGuaranteedInDays)}`,
      ].filter(s => s).join(', ');
      return `${storageTypeDisplay[s.storageType]} for ${timeSpans}`;
    })
    .join('; ');

  return `${joined}`;
};

export const splitStorages = (storages: ProductStorage[]) => {
  return {
    shelfLifeDaysAmbient: storages.find(s => s.storageType === 'ambient')?.shelfLifeInDays,
    shelfLifeDaysAmbientGuaranteed: storages.find(s => s.storageType === 'ambient')?.shelfLifeGuaranteedInDays,
    shelfLifeDaysRefrigerated: storages.find(s => s.storageType === 'refrigerate')?.shelfLifeInDays,
    shelfLifeDaysRefrigeratedGuaranteed: storages.find(s => s.storageType === 'refrigerate')?.shelfLifeGuaranteedInDays,
    shelfLifeDaysFrozen: storages.find(s => s.storageType === 'frozen')?.shelfLifeInDays,
    shelfLifeDaysFrozenGuaranteed: storages.find(s => s.storageType === 'frozen')?.shelfLifeGuaranteedInDays,
  };
};

export const StorageSchema = yup.object().shape({
  storageType: yup.string().oneOf(['ambient', 'refrigerate', 'frozen']).required(),
  shelfLifeInDays: yup.number().min(1).required('Required'),
  shelfLifeGuaranteedInDays: yup
    .number()
    .min(0)
    // allow empty string
    .transform((value, originalValue) => ((originalValue === '') ? 0 : value)),
});

export const EditVariantStorageTypeValues = flatMap(ProductStorageTypeValues.map(v => [v, `${v}-guaranteed` as const]));
export type EditVariantStorageType = typeof EditVariantStorageTypeValues[number];

export const EditVariantStorageDivisionValues = ['days', 'months', 'years'] as const;
export type EditVariantStorageDivision = typeof EditVariantStorageDivisionValues[number];

export type EditVariantStorage = {
  type: EditVariantStorageType,
  count: string,
  division: EditVariantStorageDivision,
}
export const EditVariantStorageSchema = yup.object().shape({
  type: yup.string().oneOf(EditVariantStorageTypeValues).required(),
  count: yup.number().min(1, 'Must be at least 1'),
  division: yup.string().oneOf(EditVariantStorageDivisionValues).required()
    // allow empty string
    .transform((value, originalValue) => ((originalValue === '') ? 0 : value)),
});

export const shelfLifeDaysToDivisionAndCount = (shelfLifeDays: number): Pick<EditVariantStorage, 'count' | 'division'> => {
  if (shelfLifeDays % 30 === 0) {
    return {
      division: 'months',
      count: (shelfLifeDays / 30).toString(),
    };
  }
  if (shelfLifeDays % 365 === 0) {
    return {
      division: 'years',
      count: (shelfLifeDays / 365).toString(),
    };
  }
  return {
    division: 'days',
    count: (shelfLifeDays).toString(),
  };
};

export const divisionAndCountToShelfLifeDays = ({ division, count }: Pick<EditVariantStorage, 'count' | 'division'>) => {
  const num = Number(count);
  switch (division) {
    case 'days': {
      return num;
    }
    case 'months': {
      // 12 months is actually a year
      if (num % 12 === 0) {
        return (num / 12) * 365;
      }
      return num * 30;
    }
    case 'years': {
      return num * 365;
    }
    default: {
      return num;
    }
  }
};

export const productStorageToFormStorage = (productStorages: ProductStorage[]): EditVariantStorage[] => {
  return productStorages
    .filter(isNotUnexpectedByKey('storageType'))
    .flatMap((ps) => {
      const arr: EditVariantStorage[] = [];
      if (ps.shelfLifeGuaranteedInDays) {
        arr.push({
          ...shelfLifeDaysToDivisionAndCount(ps.shelfLifeGuaranteedInDays),
          type: `${ps.storageType}-guaranteed` as const,
        });
      }
      if (ps.shelfLifeInDays !== ps.shelfLifeGuaranteedInDays) {
        arr.push({
          ...shelfLifeDaysToDivisionAndCount(ps.shelfLifeInDays),
          type: ps.storageType,
        });
      }
      return arr;
    });
};

export const formStorageToApiStorage = (editVariantStorages: EditVariantStorage[]): ProductStorage[] => {
  return editVariantStorages
    .filter(storage => !!storage.count)
    .reduce((acc, curr) => {
      const { division, count, type } = curr;
      const isGuaranteed = /-guaranteed/g.test(curr.type);
      const storageType = type.replace(/-guaranteed/g, '') as ProductStorageType;
      const shelfLifeDays = divisionAndCountToShelfLifeDays({ division, count });
      const existingIndex = acc.findIndex(s => s.storageType === storageType);
      if (existingIndex === -1) {
        acc.push({
          shelfLifeInDays: shelfLifeDays,
          shelfLifeGuaranteedInDays: isGuaranteed ? shelfLifeDays : undefined,
          storageType,
        });
      } else if (isGuaranteed) {
        acc[existingIndex].shelfLifeGuaranteedInDays = shelfLifeDays;
      } else {
        acc[existingIndex].shelfLifeInDays = shelfLifeDays;
      }
      return acc;
    }, [] as ProductStorage[]);
};

/**
 * Generates an incredibly short version of the product name for
 * use in a point of sale (POS) system, that may have very
 * strict requirements on max length.
 */
export const pointOfSaleDescription = ({
  brandName,
  productName,
  eachSize,
  eachSizeUnit,
  lengthLimit,
  brandCode: suppliedBrandCode,
}: {
  brandName: string;
  brandCode?: string;
  productName: string;
  eachSize: string;
  eachSizeUnit: string;
  lengthLimit: number;
}) => {
  const brandCode = suppliedBrandCode?.toUpperCase() ?? brandName.substring(0, 3).trim().toUpperCase();
  const size = `${eachSize}${eachSizeUnit}`;
  const lengthForProductName =
    lengthLimit -
    brandCode.length - // Brand code
    1 - // Space after brand code
    size.length - // Size
    (size.length > 0 ? 1 : 0); // Space before size, if there is a size

  /*
  Define replacement spec as words that have tiered abbreviations.
  Words can be abbreviated a little bit, or a lot.
  Go through the list multiple times, abbreviating as few words as little as possible.
  Continue until a valid length is achieved, or until we can't replace any more.
  */
  const replacementSpecs = [
    ' AND | & |&',
    ' WITH | W |',
    // ' \\- |-',
    // Diets
    'Organic|Org',

    // Other; easy
    'DOUBLE|DBL',
    'GOURMET|GRMT',
    'PREBIOTIC|PREBIOTIC|PRBIOTC',
    'COLLECTION|CLLCTN',
    // States / places
    'Vermont|VT',
    'Maine|ME',
    'ADIRONDACK|AD',
    'ITALIAN|ITALIA|ITAL',

    // Flavors
    'ORIGINAL|ORIGINA|Orig',
    'CHOCOLATEY|CHOCOLATE',
    'CHOCOLATECHIP|CHOCHP',
    'CHOCOLATE CHIP|CHOCHP',
    'CHOCOLATE|Choc',
    'WATERMELON|WATERMEL|WTRMLON',
    'RASPBERRY|RSPBRY|RASP',
    'STRAWBERRY|STWBRY',
    'BLUEBERRY|BBRRY',
    'CRANBERRY|CRANBRRY|CRANBRY|CRANB|CRAN',
    'PINEAPPLE|PNAPPLE|PNAPPL',
    'APPLE|APPL',
    'BERRY|BRRY|BRY',
    'PEANUT BUTTER|PNUT BUTTER|PB',
    'DARK|DRK',
    'VANILLA|VANIL|VAN',
    'CARAMEL|CRML',
    'LATTE|LTTE',
    'GLAZE|GLZ',
    'MANGO|MANG',
    'ROOT BEER|RTBEER',
    'LEMONGRASS|LMNGRSS|LMNGRS',
    'ROSEMARY|RSMRY',
    'Almond|ALMND|ALMD',
    'BLOOD ORANGE|BLDORNG',
    'OATMEAL|OATML',
    'PUMPKIN|PMPKN',
    'BOURBON|BRBN',
    'SOUR CREAM|SRCRM',
    'FRENCH ONION|FRNONION',
    'JALAPENO|JLPENO',
    'HABANERO|HABNERO|HBNERO|HBNRO|HABA',
    'EVERYTHING|EVRYTHNG|EVRYTNG',
    'CHIPOTLE|CHIPO',

    // Ingredients
    'CASHEWS|CASHEW|CAHEW',
    'PEANUT|PNUT',
    'PECAN|PCN',
    'PISTACHIO|PIST',
    'SEA SALT|SEASALT|SSLT|SEA',
    'GRANOLA|GRNLOA',
    'TOMATO|TOMAT|TOMA',
    'HONEY|HNY',
    'COCONUT|CCNUT',
    'LAVENDER|LAVNDR|LAV',
    'AVOCADO|AVO',
    'CHICKPEA|CKPEA',
    'CHEDDAR|CHED',
    'PARMESAN HERB|PARMHERB',
    'SHORTBREAD|SHTBRD',
    'TOFFEE|TOF',
    'PEPPER|PEPP',
    'ESPRESSO|ESPRSO',
    'HORSERADISH|HRSRDSH',
    'SUGAR|SUGA',
    'BACON|BCN',
    'KEY LIME|KEYLME',

    // Adjectives?
    'CAFFEINE FREE|CAFFREE',
    'PREMIUM|PREM',
    'SWEET|Swee|SWT',
    'Salted|SLTD',
    'SPICED|SPICE',
    'GRAIN FREE|GRNFREE',
    'GRAINFREE|GRNFREE',
    'REFRIED|RFRD',
    'EXTRA|XTRA',
    'TRADITIONAL|TRADIT',
    'GLUTEN FREE|GF',
    'VEGAN|VG',
    'SPECIALTY|SPECIALT|SPECIAL',
    'SPICY|SPIC',
    'BALSAMIC|BLSMIC',
    'CARAMELIZED|CARAMELI|CRMLZD',
    'GRADE A|GRD A',
    'GRADE B|GRD B',
    'WHITE|WHT',
    'DOUBLE|DOUBL|DOBL|2X',
    'TRIPLE|TRPL|3X',
    'CERTIFIED|CERT',

    //
    'GRAIN|GRN',

    // Other
    '\\.|', // Prevent '.' from being interpreted as aggressive regex
    'COCKTAIL|COCKTAI',
    'MILK|MLK',
    'PLANT|PLNT',
    'SQUEEZES|SQUEEZE|SQZZE',
    'CRISPED RICE|CRPSRICE',
    'KETTLE|KETTL|KETT',
    'BRITTLE|BRTTL',
    'STROOPWAFEL|STRPWFFL',
    'VEGETABLE|VEGE',
    'MACADAMIA|MCDMA',
    'LOLLIPOPS|LLPOPS|LLPOPS|LLPOP|LOLL',
    'HYDRATION|HYDRA',
    'COOKIES|COOKIE|CKIES',
    'REINDEER|REIND',
    'PASSION|PASS',
    'SMOKED|SMKD',
    'Barbeque|BBQ',
    'BREAKER|BRKR',
    'PLAIN|PLN',
    'ASSORTED|ASSRTD|ASS',
    'BRICK OVEN PIZZA|BRKPIZZA',
    'MEXICAN|MEX',
    'BRICK|BRK',
    'PROTEIN|PRTN',
    'POTATO CHIPS|CHIPS',

    'CORE SEASONAL COLLECTION|CSC',
  ];

  let pName = productName.toUpperCase().trim();

  // Remove redundant brand name & size
  pName = pName.replace(brandName.toUpperCase(), '');
  if (eachSize && eachSizeUnit) {
    pName = pName.replace(new RegExp(`${eachSize}\\s{0,1}${eachSizeUnit.toUpperCase()}`), '');
  }
  pName = pName.trim();

  // Go through replacements until it's short enough
  const maxReplacementDepth = replacementSpecs.reduce((res, replacement) => {
    // How many replacements we can do according to this string
    const depth = (replacement.match(/\|/g) || []).length;
    return depth > res ? depth : res;
  }, 1);
  let currentDepth = 0;
  while (currentDepth < maxReplacementDepth) {
    if (pName.length <= lengthForProductName) {
      // The name is short enough; mission accomplished
      break;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const spec of replacementSpecs) {
      const words = spec.split('|');
      const targetWord = words[currentDepth]?.toUpperCase();
      const replacement = words[currentDepth + 1]?.toUpperCase();

      if (replacement === undefined) {
        // Not enough words to replace any more at this depth
        // eslint-disable-next-line no-continue
        continue;
      }
      if (targetWord.startsWith(' ')) {
        // Special case; Don't replace with word boundrys
        pName = pName.replace(new RegExp(`${targetWord}`, 'g'), replacement);
      } else {
        // Replace with word boundrys
        pName = pName.replace(new RegExp(`\\b${targetWord}\\b`, 'g'), replacement);
      }

      if (pName.length <= lengthForProductName) {
        // The name is short enough; mission accomplished
        break;
      }
    }

    currentDepth += 1;
  }

  // TODO: If it's not short enough after replacements, remove vowels from a couple words

  // Finally it's still not short enough, just substring it
  if (pName.length > lengthForProductName) {
    pName = pName.substring(0, lengthForProductName);
  }

  return [brandCode, pName, size]
    .filter(s => s.trim())
    .map(s => s.toUpperCase()).join(' ');
};

// Product packaging

export const toWeightUnit = (str: string | undefined) => {
  if (isEmpty(str)) {
    return undefined;
  }
  const decoded = ProductVariantPackagingWeightUnit.decode(str);
  if ('left' in decoded) {
    return undefined;
  }
  return decoded.right;
};

export const toDistanceUnit = (str: string | undefined) => {
  if (isEmpty(str)) {
    return undefined;
  }
  const decoded = ProductVariantPackagingDistanceUnit.decode(str);
  if ('left' in decoded) {
    return undefined;
  }
  return decoded.right;
};

export const isEmptyPackaging = (packaging: ProductVariantPackaging) => {
  return !packaging.depth
    && !packaging.distanceUnit
    && !packaging.height
    && !packaging.itemCount
    && !packaging.weight
    && !packaging.weightUnit
    && !packaging.width
    && !packaging.itemsPerLayer
    && !packaging.layerCount;
};

type CamelCaseLevel = SnakeToCamelCase<ExcludeUnexpected<ProductVariantPackagingLevel>>;
const isCamelCaseLevel = (s: string): s is CamelCaseLevel => {
  return ProductVariantPackagingLevelValues.map(s => camelCase(s)).includes(s);
};
const toCamelCaseLevel = (s: ProductVariantPackagingLevel) => {
  const cc = camelCase(s.toString());
  return isCamelCaseLevel(cc) ? cc : undefined;
};
type PackagingField = keyof Omit<ProductVariantPackaging, 'level'>;
export type AllPackagingKeys = `${CamelCaseLevel}${Capitalize<PackagingField>}`;
export const splitPackagings = (packagings: ProductVariantPackaging[]) => {
  return ProductVariantPackagingLevelValues.reduce((acc, level) => {
    const packaging = packagings.find(p => p.level === level);
    const camelCaseLevel = toCamelCaseLevel(level);
    if (!camelCaseLevel) {
      return acc;
    }
    const fields: PackagingField[] = [
      'itemCount',
      'itemLevel',
      'weight',
      'weightUnit',
      'width',
      'height',
      'depth',
      'distanceUnit',
      'layerCount',
      'itemsPerLayer',
    ];
    const newFields = fields.reduce((prev, field) => {
      const pascalCasedField = upperFirst(field) as Capitalize<PackagingField>;
      prev[`${camelCaseLevel}${pascalCasedField}`] = packaging?.[field]?.toString() ?? '';
      if (camelCaseLevel === 'pallet') {
        prev[`${camelCaseLevel}ItemsPerLayer`] = packaging?.itemsPerLayer?.toString() ?? '';
        prev[`${camelCaseLevel}LayerCount`] = packaging?.layerCount?.toString() ?? '';
      }
      return prev;
    }, {} as Record<AllPackagingKeys, string>);
    return {
      ...acc,
      ...newFields,
    };
  }, {} as Record<AllPackagingKeys, string>);
};

export const productVariantPackagingToDisplayInfo = (props: {
  packaging: ProductVariantPackaging,
  variant: Pick<ProductVariant, 'eachPieceCount' | 'eachPieceName' | 'eachPieceNamePlural' | 'eachName' | 'eachCount' | 'eachNamePlural'>,
}) => {
  const { packaging, variant } = props;
  const levelDisplayName = (level: ProductVariantPackagingLevel) => {
    if (isUnexpected(level)) return undefined;
    switch (level) {
      case 'unit':
        return 'Unit';
      case 'case':
        return 'Case';
      case 'master_case':
        return 'Master Case';
      case 'pallet':
        return 'Pallet';
      default:
        throw assertExhaustive(level);
    }
  };
  const level = levelDisplayName(packaging.level);

  const dimensions = (() => {
    if (isUnexpected(packaging.distanceUnit) || !packaging.distanceUnit) return undefined;
    if (!packaging.width || !packaging.height || !packaging.depth) return undefined;
    switch (packaging.distanceUnit) {
      case 'in':
        return `${packaging.width.toString()}" W x ${packaging.height.toString()}" H x ${packaging.depth.toString()}" D`;
      case 'cm':
        return `${packaging.width.toString()} cm W x ${packaging.height.toString()} cm H x ${packaging.depth.toString()} cm D`;
      default:
        throw assertExhaustive(packaging.distanceUnit);
    }
  })();

  const weight = (() => {
    if (!packaging.weight || !packaging.weightUnit) return undefined;
    // eslint-disable-next-line no-underscore-dangle
    return `${packaging.weight.toString()} ${isUnexpected(packaging.weightUnit) ? packaging.weightUnit.__unexpected : packaging.weightUnit}`;
  })();

  const size = (() => {
    if (isUnexpected(packaging.level)) return undefined;
    switch (packaging.level) {
      case 'unit':
        if (variant.eachPieceCount) {
          return `${variant.eachPieceCount} ${variant.eachPieceCount === 1 ? variant.eachPieceName : variant.eachPieceNamePlural}`;
        }
        return `1 ${variant.eachName ?? 'unit'}`;
      case 'case':
        if (packaging.itemCount) {
          return `${packaging.itemCount} ${packaging.itemCount === 1 ? (variant.eachName ?? 'unit') : (variant.eachNamePlural ?? 'units')}`;
        }
        return undefined;
      case 'master_case':
        if (packaging.itemCount && packaging.itemLevel) {
          return `${packaging.itemCount} ${pluralize((packaging.itemLevel === 'case') ? 'case' : 'unit', packaging.itemCount)}`;
        }
        if (packaging.itemCount) {
          return `${packaging.itemCount} items`;
        }
        return undefined;
      case 'pallet':
        if (!packaging.itemsPerLayer || !packaging.layerCount || !packaging.itemLevel) return undefined;
        return `TI ${packaging.itemsPerLayer} x HI ${packaging.layerCount} = ${packaging.itemsPerLayer * packaging.layerCount} ${levelDisplayName(packaging.itemLevel)}s`;
      default:
        throw assertExhaustive(packaging.level);
    }
  })();

  return {
    level,
    dimensions,
    weight,
    size,
  };
};
