import BigNumber from 'bignumber.js';

import { formatMoney, isTruthy, Slug } from '@mablemarket/common-lib';
import {
  CategoryMembershipInput, SellerAdminDisplayInfo, SellerDisplayInfo, SellerInput, SellerPersonalDisplayInfo, ShippingPolicy,
} from '@mablemarket/core-api-client';

import { removeBlankStringsForKeys } from '../utils';

import { cleanContactForApi } from './ContactHelpers';


export function sellerSkeleton(): Partial<SellerAdminDisplayInfo> {
  return {
    id: undefined,
    status: 'draft',
    featured: false,
    useAltLayout: false,
    displaySkuOnList: false,
    cold: false,
    name: '',
    slug: '' as Slug,
    description: '',
    primaryContact: {
      email: '',
      firstName: '',
      hubSpotId: '',
      lastName: '',
      phone: '',
    },
    billingContact: {
      email: '',
      firstName: '',
      hubSpotId: '',
      lastName: '',
      phone: '',
    },
    shipFromAddress: undefined,
    facebookId: '',
    twitterId: '',
    instagramId: '',
    youTubeUrl: '',
    websiteUrl: '',
    unlisted: false,
    salesRepOrdering: false,
    comingSoon: false,
    orderMinimum: undefined,
    freeShippingMinimum: undefined,
    shipWithMableEnabled: true,
    productsAffectedByHeat: false,
    memo: '',
    referralCode: '',
    categories: [],
    distributor: false,
    allowsSamples: false,
    shippingCapMultiplier: new BigNumber('0.25'),
    shippingPaymentMultiplier: new BigNumber('0.5'),
    mableMarketplaceEnabled: true,
  };
}

export const popularBrandIds = [458, 868, 414, 904, 23, 152, 191, 471];

export const freeShippingMin = ({
  shippingPolicy,
  freeShippingMinimum,
  shoutFreeShipping = true,
}: {
  shippingPolicy: ShippingPolicy,
  freeShippingMinimum?: BigNumber,
  shoutFreeShipping?: boolean,
}) => {
  const free = shoutFreeShipping ? 'FREE' : 'Free';
  if (shippingPolicy === 'free') {
    return `${free} shipping`;
  }

  if (shippingPolicy === 'sellerMinimum' && freeShippingMinimum !== undefined) {
    return `${free} shipping over ${formatMoney(freeShippingMinimum, { alwaysShowCents: false })}`;
  }

  // shippingPolicy === 'none' || 'capped'
  return undefined;
};

export const infoLine = (seller: SellerDisplayInfo, opts: {
  separator?: string;
  includeDisplayLocation?: boolean;
  includeShippingLeadTime?: boolean;
  includeProductCount?: boolean;
  includeFreeShipping?: boolean;
  shoutFreeShipping?: boolean;
  includeOrderMinimum?: boolean;
}) => {
  const {
    separator = ' • ',
    includeDisplayLocation = true,
    includeShippingLeadTime = false,
    includeProductCount = true,
    includeFreeShipping = true,
    shoutFreeShipping = false,
    includeOrderMinimum = true,
  } = opts;

  const orderMin = seller.orderMinimum ? `${formatMoney(seller.orderMinimum, { alwaysShowCents: false })} order minimum` : 'No order minimum';
  const modifier = seller.variantCount === 1 ? 'product' : 'products';
  const productCount = `${seller.variantCount ?? 0} ${modifier}`;
  const freeShippingMinDisplay = freeShippingMin({
    shippingPolicy: seller.shippingPolicy,
    freeShippingMinimum: seller.freeShippingMinimum,
    shoutFreeShipping,
  });

  const details = [
    includeDisplayLocation && seller.displayLocation,
    includeShippingLeadTime && seller.shippingLeadTime,
    includeProductCount && productCount !== '0' && productCount,
    includeOrderMinimum && orderMin,
    includeFreeShipping && freeShippingMinDisplay,
  ].filter(isTruthy).join(separator);
  return details;
};

export function convertToSellerInput(seller: Partial<SellerAdminDisplayInfo>): (SellerInput | undefined) {
  // Check for the presence of the required fields
  // TODO: Validate for non-blank strings, maybe with Yup
  if (!seller.name || !seller.slug) {
    return undefined;
  }

  // Convert empty strings to undefined
  // TODO: Type safety
  const result = removeBlankStringsForKeys(seller, [
    'description', 'websiteUrl', 'city', 'state', 'hubSpotCompanyId', 'facebookId', 'twitterId',
    'instagramId', 'youTubeUrl', 'orderMinimum', 'freeShippingMinimum', 'shippingCapPaymentMultiplier',
    'mableShippingMaxPaymentMultiplier',
  ]) as SellerInput;


  if (seller.categories) {
    result.categoryMemberships = seller.categories.map((catMembership, index): CategoryMembershipInput => {
      return {
        categoryId: catMembership.id,
        isPrimary: catMembership.isPrimary,
        featuredIndex: Number(catMembership.featuredIndex ?? 0),
      };
    });
  }
  if (seller.shipFromAddress) {
    result.shipFromAddressId = seller.shipFromAddress.id;
  }

  // Clean relation objects
  result.primaryContact = cleanContactForApi(seller.primaryContact);
  result.billingContact = cleanContactForApi(seller.billingContact);

  return result;
}

export const showProductsAffectedByHeatWarning =
(seller: Pick<SellerDisplayInfo, 'productsAffectedByHeat'> | undefined, forDate: Date) => {
  if (!seller?.productsAffectedByHeat) {
    return false;
  }

  const year = forDate.getFullYear();
  const summerStart = new Date(`01 Jun ${year} 00:00:00 GMT`);
  const summerEnd = new Date(`7 Sep ${year} 00:00:00 GMT`);

  return summerStart.getTime() < forDate.getTime() &&
  forDate.getTime() < summerEnd.getTime();
};

export const isValidNumber = [
  'valid-num',
  'Must be a valid number',
  (value: unknown) => ((typeof value === 'number' || Boolean(value)) ? Number.isFinite(Number(value)) : true),
] as const;

/** Accepts a number or BigNumber (as string) */
export const isLessThanNumber = (max: number) => [
  'less-than-num',
  `Must be less than ${max}`,
  (value: number | string) => (typeof value === 'number' ? value < max : new BigNumber(value).lt(new BigNumber(max))),
] as const;

/** Accepts a number or BigNumber (as string) */
export const isLessThanEqToNumber = (max: number) => [
  'less-than-eq-to-num',
  `Must be less than or equal to ${max}`,
  (value: number | string) => (typeof value === 'number' ? value <= max : new BigNumber(value).lte(new BigNumber(max))),
] as const;

// An Instagram username is limited to 30 characters and must contain only letters, numbers, periods, and underscores.
// You can’t include symbols or other punctuation marks as a part of your username.
const instaRegex = /^[\w._]{1,30}$/;
export const isValidInstaHandle = [
  'valid-insta-handle',
  'Must be a valid Instagram handle',
  (value: unknown) => (value === undefined || (typeof value === 'string' && instaRegex.test(value))),
] as const;

// https://help.twitter.com/en/managing-your-account/twitter-username-rules#error
const twitterRegex = /^[\w_]{1,15}$/;
export const isValidTwitterHandle = [
  'valid-twitter-handle',
  'Must be a valid Twitter handle',
  (value: unknown) => (value === undefined || (typeof value === 'string' && twitterRegex.test(value))),
] as const;

// https://www.facebook.com/help/105399436216001/?helpref=search
// Adding dash, but it's supposedly not allowed. Facebook does a 301 with dashes on some pages.
const facebookRegex = /^[\w.-]+$/;
export const isValidFacebookHandle = [
  'valid-facebook-handle',
  'Must be a valid Facebook handle',
  (value: unknown) => (value === undefined || (typeof value === 'string' && facebookRegex.test(value))),
] as const;

export type ExternalWholesaleWidgetType = ('button' | 'standard');
export const embedWidgetCode = ({
  type,
  sellerSlug,
  baseWebURL,
}: {
  type: ExternalWholesaleWidgetType;
  sellerSlug: string;
  /** e.g. 'https://www.meetmable.com/' */
  baseWebURL: string;
}) => {
  const [width, height] = type === 'standard' ? [500, 580] : [330, 50];
  const url = `${baseWebURL}referralwidget/${type}/${sellerSlug}`;
  return `<iframe src="${url}" width="${width}" height="${height}" scrolling="no" style="margin:0 auto;border:none;display:block;max-width:100%;"></iframe>`;
};

export const isCstoreReady = (seller?: Pick<SellerPersonalDisplayInfo, 'partnerProgramMemberships'>) => {
  return !!seller?.partnerProgramMemberships?.some(membership => membership.partnerAccountName === 'mable');
};
